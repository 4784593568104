import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import Image from 'next/image'

//* Style
import ImageStyle from './style'

const CustomImage = forwardRef(({ src, alt, objectFit, className, priority, ...props }, ref) => {
    const imgRef = useRef()

    useImperativeHandle(ref, () => imgRef.current, [])

    return (
        <ImageStyle className={`imageCont ${className || ''}`} ref={imgRef}>
            <Image
                fill
                unoptimized={src && src.includes('.svg')}
                sizes="(min-width: 320px) 100%"
                src={src || '/images/default.jpeg'}
                alt={alt || 'image'}
                draggable={false}
                priority={priority}
                loading={!priority ? 'lazy' : 'eager'}
                style={{ objectFit: objectFit || 'contain' }}
            />
        </ImageStyle>
    )
})

CustomImage.displayName = 'CustomImage'

export default CustomImage
