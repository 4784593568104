import { createGlobalStyle } from 'styled-components'

function spaceGenerator() {
	let str = ''

	for (let i = 1; i <= 200; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`
	}

	return str
}

const Variables = createGlobalStyle`

    :root {
        //! Colors
        /* Primary Colors */
        --primaryColor1: ${(props) => props.theme.colors.ColorWhite};
        --primaryColor2: ${(props) => props.theme.colors.ColorBlack};
        --primaryColor3: ${(props) => props.theme.colors.ColorBlue};

        /* Secondary Colors */
        --secondaryColor1: ${(props) => props.theme.colors.ColorDarkGray};
        --secondaryColor2: ${(props) => props.theme.colors.ColorErrorRed};
        --secondaryColor3: ${(props) => props.theme.colors.ColorSuccessGreen};

        /* Layer color */
        --layerColor: #212121;

        //! Screen Sizes
        --desktopSizeXL:${(props) => props.theme.mediaQuery.DesktopSizeXL};
        --desktopSizeL:${(props) => props.theme.mediaQuery.DesktopSizeL};
        --desktopSizeM:${(props) => props.theme.mediaQuery.DesktopSizeM};
        --desktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
        --desktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
        --tabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
        --tabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
        --mobileSize: ${(props) => props.theme.mediaQuery.MobileSize};
        --mobileSizeS: ${(props) => props.theme.mediaQuery.MobileSizeS};
        --mobileSizeSMin: ${(props) => props.theme.mediaQuery.MobileSizeSMin};

        //! Spacing
        --spSize: 8px;
        --sp0-5x: calc(var(--spSize) * 0.5);
        --sp1-5x: calc(var(--spSize) * 1.5);
        --sp2-5x: calc(var(--spSize) * 2.5);
        --sp3-5x: calc(var(--spSize) * 3.5);
        ${spaceGenerator()}

        //! Transition
        --trTime: 0.3s;

        //! Line heights
        --lineHeight: 1.6;
        --lineHeightS: 1.4;
        --lineHeightXS: 1.2;
    }

@media only screen and  (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) {
    :root {
        --h1: 312px;
        --h2: 168px;
        --h3: 136px;
        --h4: 104px;
        --h5: 72px;
        --h6: 48px;
        --p1: 40px;
        --p2: 32px;
        --p3: 24px;
        --p: 20px;

        //! Section distance
        --sectionsDistance: var(--sp33x);
        --containerPadLR: var(--sp14x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeL}) {
    :root {
        --h1: 240px;
        --h2: 120px;
        --h3: 104px;
        --h4: 80px;
        --h5: 56px;
        --h6: 40px;
        --p1: 32px;
        --p2: 24px;
        --p3: 20px;
        --p: 16px;

        //! Section distance
        --sectionsDistance: var(--sp25x);
        --containerPadLR: var(--sp11x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeM}) {
    :root {
        --h1: 184px;
        --h2: 96px;
        --h3: 80px;
        --h4: 64px;
        --h5: 48px;
        --h6: 32px;
        --p1: 24px;
        --p2: 20px;
        --p3: 16px;
        --p: 16px;

        //! Section distance
        --sectionsDistance: var(--sp20x);
        --containerPadLR: var(--sp10x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeS}) {
    :root {
        --h1: 176px;
        --h2: 88px;
        --h3: 72px;
        --h4: 56px;
        --h5: 40px;
        --h6: 32px;
        --p1: 24px;
        --p2: 20px;
        --p3: 16px;
        --p: 16px;

        //! Section distance
        --sectionsDistance: var(--sp20x);
        --containerPadLR: var(--sp5x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.DesktopSizeXS}) {
    :root {
        --h1: 160px;
        --h2: 72px;
        --h3: 64px;
        --h4: 48px;
        --h5: 40px;
        --h6: 32px;
        --p1: 24px;
        --p2: 20px;
        --p3: 16px;
        --p: 16px;

        //! Section distance
        --sectionsDistance: var(--sp15x);
        --containerPadLR: var(--sp5x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.TabletSize}) {
    :root {
        --h1: 128px;
        --h2: 64px;
        --h3: 56px;
        --h4: 40px;
        --h5: 32px;
        --h6: 24px;
        --p1: 20px;
        --p2: 18px;
        --p3: 16px;
        --p: 16px;

        //! Section distance
        --sectionsDistance: var(--sp12x);
        --containerPadLR: var(--sp4x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) =>
	props.theme.mediaQuery.TabletSizeS}) {
    :root {
        --h1: 112px;
        --h2: 64px;
        --h3: 48px;
        --h4: 32px;
        --h5: 32px;
        --h6: 20px;
        --p1: 18px;
        --p2: 18px;
        --p3: 16px;
        --p: 14px;

        //! Section distance
        --sectionsDistance: var(--sp12x);
        --containerPadLR: var(--sp3x);
    }
}

@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
    :root {
        --h1: 64px;
        --h2: 48px;
        --h3: 40px;
        --h4: 24px;
        --h5: 24px;
        --h6: 20px;
        --p1: 18px;
        --p2: 18px;
        --p3: 16px;
        --p: 14px;

        //! Section distance
        --sectionsDistance: var(--sp10x);
        --containerPadLR: var(--sp1-5x);
    }
}
`

export default Variables
