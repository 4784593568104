import { memo as Memo, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'

//* HOCs
import { withLanguageContext, withUIContext, withDataContext } from 'context'

//* Components
import CustomLink from 'components/common/CustomLink'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'
import Logo from 'components/common/Logo'
import Animate from 'components/common/Animate'
import Container from 'components/common/Container'

//* Styles
import Footer from './style'

//* Config
import config from 'helpers/_config'

const AppFooter = Memo(({ global, selectedLang, winWidth, screenSizes }) => {
    //! Router
    const router = useRouter()

    //! Refs
    const footerMenuItems = useRef([])
    const footerSocials = useRef([])
    const horizontalLine1 = useRef()
    const horizontalLine2 = useRef()
    const horizontalLine3 = useRef()
    const verticalLine1 = useRef()
    const verticalLine2 = useRef()
    const phone = useRef()
    const mail = useRef()
    const address = useRef()

    //! Global Data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! Store routes
    const storeRoutes = useMemo(() => {
        const routes = Object.values(config.routes)

        return routes.filter(item => !['blog', 'solution'].includes(item.name)).map(
            (route, index) =>
                route.footerMenu && (
                    <div key={index} className='routes-list-item' ref={(ref) => (footerMenuItems.current[index] = ref)}>
                        <CustomLink url={route.path}>
                            <Text className={'p font-montserratarm-medium uppercase'} text={route.name} />
                        </CustomLink>
                    </div>
                )
        )
    }, [])

    //! Information store
    const infoStore = useMemo(() => {
        if (globalData) {
            return (
                <>
                    <div className='info-wrap'>
                        <Text className={'p3 font-montserratarm-regular footer-address'} text={globalData.address} ref={address} />
                        <a
                            rel='noopener noreferrer'
                            aria-label='email'
                            href={`mailto:${globalData.email}`}
                            className={'font-montserratarm-regular mail'}
                            ref={mail}
                        >
                            {globalData.email}
                        </a>
                        <a
                            rel='noopener noreferrer'
                            aria-label='phone'
                            href={`tel:${globalData.phone.replace(/\s+/g, '')}`}
                            className={'font-montserratarm-regular phone'}
                            ref={phone}
                        >
                            {globalData.phone}
                        </a>
                    </div>
                </>
            )
        }
    }, [globalData, address, mail, phone])

    //! Store socials
    const storeSocials = useMemo(() => {
        if (globalData) {
            const socials = Object.values(globalData.socials)
            const socialsKeys = Object.keys(globalData.socials)

            return socials.map((social, index) => (
                <div key={index} ref={(ref) => (footerSocials.current[index] = ref)}>
                    <CustomLink
                        target={'_blank'}
                        rel='noopener noreferrer'
                        url={social}
                        className={`social-icon-wrap ${socials.length - 1 === index ? 'last' : ''}`}
                        ariaLabel={socialsKeys[index]}
                    >
                        <Icon name={socialsKeys[index]} className={'social-icon'} />
                    </CustomLink>
                </div>
            ))
        }
    }, [globalData])

    //! Global Store
    const globStore = useMemo(() => {
        return winWidth >= screenSizes.tabletSizeS ? (
            <>
                <div className={`first item col-4`}>
                    <div className={'routes-list'}>{storeRoutes}</div>
                    <div ref={verticalLine1} className={`verticalLine`} />
                </div>
                <div className={`second item col-4`}>
                    {infoStore}
                    <div ref={verticalLine2} className={`verticalLine`} />
                </div>
                <div className={`third item col-4`}>
                    <div className={`storeSocialsWrap`}>{storeSocials}</div>
                </div>
            </>
        ) : (
            <>
                <div className={`first item col-m-8`}>
                    <div className={'routes-list'}>{storeRoutes}</div>
                    <div ref={verticalLine1} className={`verticalLine`} />
                </div>
                <div className={`third item col-m-4`}>
                    <div className={`storeSocialsWrap`}>{storeSocials}</div>
                </div>
                <div className='line' ref={horizontalLine3} />
                <div className={`second item col-m-12`}>{infoStore}</div>
            </>
        )
    }, [storeRoutes, infoStore, storeSocials, winWidth, screenSizes.tabletSizeS])

    return (
        <Footer>
            <Animate
                durationOpacity={0.5}
                stagger={0.04}
                delay={0.1}
                params={{
                    opacityVertical: [footerMenuItems, address, mail, phone, footerSocials],
                }}
                dependency={router.asPath}
                footer={true}
            >
                <Animate
                    stagger={0.4}
                    params={{
                        lineVerticalPosVersion: [verticalLine1, verticalLine2],
                    }}
                    dependency={[router.asPath, winWidth]}
                    footer={true}
                >
                    <Animate
                        stagger={0.2}
                        params={{
                            lineHorizontal: [horizontalLine1, horizontalLine2, horizontalLine3],
                        }}
                        dependency={router.asPath}
                        footer={true}
                    >
                        <div className='line' ref={horizontalLine1} />
                        <div className='footer-main-content-wrap'>
                            <Container row>{globStore}</Container>
                        </div>
                        <div className='line' ref={horizontalLine2} />
                    </Animate>
                </Animate>
            </Animate>

            <div className='lower-footer'>
                <div className='copyright-wrap'>
                    <div className='copyright'>
                        <Text text={`© AAB CONSTRUCTION ${new Date().getFullYear()}`} className='p primaryColor1 font-montserratarm-regular' />
                    </div>
                    <div className='made-by-wrap'>
                    </div>
                </div>
                <Logo type={'footer'} className={'footer-logo'} />
            </div>
        </Footer>
    )
})

export default withLanguageContext(withUIContext(withDataContext(AppFooter, ['global']), ['screenSizes', 'winWidth']), ['selectedLang'])
