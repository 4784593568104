import { memo as Memo, useMemo, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Style
import HeaderStyle from './style'

//* Components
import Logo from 'components/common/Logo'
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'
import LanguageSwitcher from 'components/common/LanguageSwitcher'
import MobileMenu from './MobileMenu'
import LottieAnimation from 'components/common/LottieAnimation'
import Animate from 'components/common/Animate'

//* config
import config from 'helpers/_config'

//* Lottie
import burgerMenu from 'lottieFiles/burgerMenu.json'

const AppHeader = Memo(({ winWidth, screenSizes, isBurgerMenuOpen, toggleBurgerMenu, closeBurgerMenu }) => {
	//! Router
	const router = useRouter()

	//! States
	const [headerHeight, setHeaderHeight] = useState()

	//! Refs
	const menuItems = useRef([])
	const headerRef = useRef()
	const logoRef = useRef()
	const languageSwitcherRef = useRef()
	const burgerMenuIconRef = useRef()
	const lineRef = useRef()

	//! Store menu routes
	const storeRoutes = useMemo(() => {
		const routes = Object.values(config.routes)

		return routes.filter(item => !['blog', 'solution'].includes(item.name)).map(
			(route, index) =>
				route.headerMenu && (
					<li key={index} className={`routes-list-item ${router.pathname === route.path ? ' active' : ''}`}>
						<CustomLink ref={(ref) => (menuItems.current[index] = ref)} url={route.path}>
							<Text className={'p font-montserratarm-medium uppercase'} text={route.name} />
						</CustomLink>
					</li>
				)
		)
	}, [router])

	//! Close burger menu when route changes
	useEffect(() => {
		closeBurgerMenu()
	}, [router.asPath])

	//! Count header height
	useEffect(() => {
		setHeaderHeight(headerRef.current.getBoundingClientRect().height)
	}, [winWidth])

	//! Is header has background
	const isHeaderHasBackground = useMemo(() => {
		return (router.pathname === '/' && winWidth >= screenSizes.tabletSizeS) ||
			router.pathname === '/about-us' ||
			router.pathname === '/expertise' ||
			router.route === '/expertise/[slug]' ||
			router.pathname === '/career' ||
			router.pathname === '/solution' ||
			router.pathname === '/materials'
			? `opacityHeader`
			: ''
	}, [router, isBurgerMenuOpen, winWidth, screenSizes.tabletSizeS])

	const menuStore = useMemo(() => {
		return winWidth < screenSizes.desktopSizeS ? (
			<>
				<LanguageSwitcher ref={languageSwitcherRef} />
				<div onClick={toggleBurgerMenu} ref={burgerMenuIconRef} className={'burger-menu-icon'}>
					<LottieAnimation animData={burgerMenu} autoplay={false} isPlay={isBurgerMenuOpen} isReverse={!isBurgerMenuOpen} loop={false} />
				</div>
			</>
		) : (
			<>
				<ul className='routes-list'>{storeRoutes}</ul>
				<LanguageSwitcher ref={languageSwitcherRef} />
			</>
		)
	}, [winWidth, toggleBurgerMenu, isBurgerMenuOpen, screenSizes.desktopSizeS, storeRoutes, , languageSwitcherRef, burgerMenuIconRef])

	//! Burger Menu
	const mobileMenu = useMemo(() => {
		if (winWidth < screenSizes.desktopSizeS) {
			return <MobileMenu headerHeight={headerHeight} className={isHeaderHasBackground} isBurgerMenuOpen={isBurgerMenuOpen} />
		}
	}, [winWidth, screenSizes.desktopSizeS, isBurgerMenuOpen, headerHeight, isHeaderHasBackground])

	return (
		<>
			{mobileMenu}
			<Animate
				startY={'-100%'}
				params={{ opacityVertical: [logoRef, menuItems, languageSwitcherRef, burgerMenuIconRef], lineHorizontal: [lineRef] }}
				oneTime={true}
				stagger={0.06}
				durationOpacity={0.6}
			>
				<HeaderStyle ref={headerRef} headerHeight={headerHeight} className={isHeaderHasBackground}>
					<div className='header-content-wrap'>
						<Logo ref={logoRef} isBurgerMenuOpen={isBurgerMenuOpen} />

						{menuStore}
					</div>
					<div className='line-wrap'>
						<div className='line' ref={lineRef} />
					</div>
				</HeaderStyle>
			</Animate>
		</>
	)
})

export default withUIContext(AppHeader, ['screenSizes', 'winWidth', 'toggleBurgerMenu', 'isBurgerMenuOpen', 'closeBurgerMenu'])
