import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`
    .line {
        width: 0;
        opacity: 0;
        height: 1px !important;
		background-color: var(--primaryColor1);

        &-vertical {
            width: 1px;
            height: 100%;
            background-color: var(--primaryColor1);
        }
    }

    //! Primary color
    .primaryColor1 {
        color: var(--primaryColor1);

        &-bg {
            background: var(--primaryColor1);
        }
    }

    .primaryColor2 {
        color: var(--primaryColor2);

        &-bg {
            background: var(--primaryColor2);
        }
    }

    .primaryColor3 {
        color: var(--primaryColor3);

        &-bg {
            background: var(--primaryColor3);
        }
    }

    //! Secondary colors
    .secondaryColor1 {
        color: var(--secondaryColor1);

        &-bg {
            background: var(--secondaryColor1);
        }
    }

    .secondaryColor2 {
        color: var(--secondaryColor2);

        &-bg {
            background: var(--secondaryColor2);
        }
    }

    .secondaryColor3 {
        color: var(--secondaryColor3);

        &-bg {
            background: var(--secondaryColor3);
        }
    }


    //! Fonts
    .font-montserratarm {
        font-family: Montserratarm;
        
        &-light {
            font-family: Montserratarm;
            font-weight: 300!important;
        }

        &-regular {
            font-family: Montserratarm;
            font-weight: 400!important;
        }

        &-medium {
            font-family: Montserratarm;
            font-weight: 500!important;
        }

        &-semiBold {
            font-family: Montserratarm;
            font-weight: 600!important;
        }

        &-bold {
            font-family: Montserratarm;
            font-weight: 700!important;
        }
    }

    //! Text capitalize
    .uppercase {
        text-transform: uppercase;
    }

    .lowercase {
        text-transform: lowercase;
    }

    .capitalize {
        text-transform: capitalize;
    }

    .underline {
        text-decoration: underline;
    }

    //! Opacity
    .opacityZero{
        opacity: 0
    }

    //! Cursor
    .cursorPointer{
        cursor: pointer;
    }

    //! Overflow
    .overflowHidden {
        overflow: hidden;
    }
`

export default HelperClass
