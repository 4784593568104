import styled from 'styled-components'

const Footer = styled.footer`
	--itemPadding: var(--sp6x);
	--itemPadTB: var(--sp14x);
	--routesListItemMarginBottom: var(--sp5x);
	--routesListMaxHeight: var(--sp32x);
	--secondPadL: var(--sp8x);
	--footerAddressMaxWidth: var(--sp40x);
	--mailMarginTop: var(--sp7x);
	--phoneMarginTop: var(--sp5x);
	--socialIconWrapSizes: var(--sp22x);
	--socialIconSizes: var(--sp13x);
	--socialIconWrapMargin: var(--sp9x);

	--lowerFooterMarginTop: var(--sp5x);
	--lowerFooterLogoMarginTop: var(--sp11x);
	--copyRightPadding: 0 var(--sp14x);

	.third {
		opacity: 1 !important;
	}

	.verticalLine {
		position: absolute;
		background-color: var(--primaryColor1);
		width: 1px !important;
		right: 0;
		top: 0;
		opacity: 1 !important;
	}

	.footer-main-content-wrap {
		width: 100%;
		display: flex;
		position: relative;

		.row {
			padding: 0 calc(var(--itemPadding) * -1);
			margin: 0 calc(var(--itemPadding) * -1);

			.item {
				position: relative;
				padding: 0 var(--itemPadding);
			}

			.first,
			.second {
				padding-top: var(--itemPadTB);
				padding-bottom: var(--itemPadTB);
			}

			.first {
				.routes-list {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					max-height: var(--routesListMaxHeight);

					.routes-list-item {
						position: relative;
						width: fit-content;
						margin-bottom: var(--routesListItemMarginBottom);

						max-width: 50%;
						//&:nth-child(4) {
						//	margin-bottom: 0;
						//}

						@media (hover: hover) {
							&:hover {
								&:before {
									width: 100%;
								}
							}
						}

						&:before {
							content: '';
							position: absolute;
							top: 100%;
							width: 0%;
							height: 1px;
							background-color: var(--primaryColor1);
							transition: var(--trTime);
						}
					}
				}
			}

			.second {
				.info-wrap {
					padding-left: var(--secondPadL);

					.footer-address {
						max-width: var(--footerAddressMaxWidth);
					}

					.mail,
					.phone {
						display: block;
						width: fit-content;
						font-size: var(--p3);
					}

					.mail {
						margin-top: var(--mailMarginTop);
					}

					.phone {
						margin-top: var(--phoneMarginTop);
					}
				}
			}

			.third {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-end;

				.storeSocialsWrap {
					display: flex;
					align-items: center;
					overflow: hidden;

					.social-icon-wrap {
						width: var(--socialIconWrapSizes);
						height: var(--socialIconWrapSizes);
						display: flex;
						justify-content: center;
						align-items: center;
						border: 1px solid var(--primaryColor1);
						border-radius: 50%;
						transition: var(--trTime);
						margin-right: var(--socialIconWrapMargin);

						&.last {
							margin-right: 0;
						}

						.social-icon {
							font-size: var(--socialIconSizes);
							transition: var(--trTime);
						}

						@media (hover: hover) {
							&:hover {
								background-color: var(--primaryColor1);

								.social-icon {
									color: var(--primaryColor2);
								}
							}
						}
					}
				}
			}
		}
	}

	.lower-footer {
		margin-top: var(--lowerFooterMarginTop);

		.copyright-wrap {
			display: flex;
			justify-content: space-between;
			padding: var(--copyRightPadding);

			.copyright,
			.made-by-wrap {
				display: flex;
			}
		}

		.footer-logo {
			margin-top: var(--lowerFooterLogoMarginTop);
			pointer-events: none;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		--itemPadding: var(--sp5x);
		--itemPadTB: var(--sp10x);
		--routesListItemMarginBottom: var(--sp4x);
		--routesListMaxHeight: var(--sp27x);
		--secondPadL: var(--sp4x);
		--footerAddressMaxWidth: var(--sp33x);
		--mailMarginTop: var(--sp6x);
		--phoneMarginTop: var(--sp3x);
		--socialIconWrapSizes: var(--sp17x);
		--socialIconSizes: var(--sp9x);
		--socialIconWrapMargin: var(--sp6x);

		--lowerFooterLogoMarginTop: var(--sp10x);
		--lowerFooterMarginTop: var(--sp4x);
		--copyRightPadding: 0 var(--sp11x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		--itemPadding: var(--sp5x);
		--itemPadTB: var(--sp8x);
		--routesListItemMarginBottom: var(--sp4x);
		--routesListMaxHeight: var(--sp25x);
		--secondPadL: var(--sp3x);
		--footerAddressMaxWidth: var(--sp28x);
		--mailMarginTop: var(--sp4x);
		--phoneMarginTop: var(--sp3x);
		--socialIconWrapSizes: var(--sp12x);
		--socialIconSizes: var(--sp6x);
		--socialIconWrapMargin: var(--sp5x);

		--lowerFooterLogoMarginTop: var(--sp8x);
		--lowerFooterMarginTop: var(--sp3x);
		--copyRightPadding: 0 var(--sp10x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		--itemPadding: var(--sp4x);
		--itemPadTB: var(--sp8x);
		--routesListItemMarginBottom: var(--sp4x);
		--routesListMaxHeight: var(--sp25x);
		--secondPadL: var(--sp3x);
		--footerAddressMaxWidth: var(--sp34x);
		--mailMarginTop: var(--sp4x);
		--phoneMarginTop: var(--sp3x);
		--socialIconWrapSizes: var(--sp12x);
		--socialIconWrapMargin: var(--sp5x);
		--socialIconSizes: var(--sp6x);

		--lowerFooterLogoMarginTop: var(--sp8x);
		--lowerFooterMarginTop: var(--sp3x);
		--copyRightPadding: 0 var(--sp5x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		--itemPadding: var(--sp4x);
		--itemPadTB: var(--sp6x);
		--routesListItemMarginBottom: var(--sp3x);
		--routesListMaxHeight: var(--sp23x);
		--secondPadL: var(--sp2x);
		--footerAddressMaxWidth: var(--sp25x);
		--phoneMarginTop: var(--sp2x);
		--mailMarginTop: var(--sp3x);
		--socialIconWrapSizes: var(--sp10x);
		--socialIconSizes: var(--sp5x);
		--socialIconWrapMargin: var(--sp5x);

		--lowerFooterLogoMarginTop: var(--sp6x);
		--lowerFooterMarginTop: var(--sp3x);
		--copyRightPadding: 0 var(--sp5x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		--itemPadding: var(--sp4x);
		--itemPadTB: var(--sp5x);
		--routesListItemMarginBottom: var(--sp3x);
		--routesListMaxHeight: 100%;
		--secondPadL: 0;
		--footerAddressMaxWidth: var(--sp25x);
		--mailMarginTop: var(--sp4x);
		--phoneMarginTop: var(--sp3x);
		--socialIconWrapSizes: var(--sp8x);
		--socialIconSizes: var(--sp4x);
		--socialIconWrapMargin: var(--sp4x);

		--lowerFooterLogoMarginTop: var(--sp5x);
		--lowerFooterMarginTop: var(--sp3x);
		--copyRightPadding: 0 var(--sp4x);

		.footer-main-content-wrap .row .first .routes-list .routes-list-item {
			&:nth-child(4) {
				margin-bottom: var(--routesListItemMarginBottom);
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.footer-main-content-wrap .row .third .storeSocialsWrap {
			flex-direction: column;

			.social-icon-wrap {
				margin-right: 0;
			}

			div:not(:last-child) {
				margin-bottom: var(--socialIconWrapMargin);
			}
		}

		.footer-main-content-wrap .row .third {
			align-items: center;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		--itemPadding: var(--sp3x);
		--itemPadTB: var(--sp5x);
		--routesListItemMarginBottom: var(--sp3x);
		--routesListMaxHeight: 100%;
		--secondPadL: var(--sp1x);
		--footerAddressMaxWidth: 100%;
		--mailMarginTop: var(--sp4x);
		--phoneMarginTop: var(--sp3x);
		--socialIconWrapSizes: var(--sp8x);
		--socialIconSizes: var(--sp4x);
		--socialIconWrapMargin: var(--sp4x);

		--lowerFooterLogoMarginTop: var(--sp5x);
		--lowerFooterMarginTop: var(--sp3x);
		--copyRightPadding: 0 var(--sp3x);

		.footer-main-content-wrap .row .first .routes-list .routes-list-item {
			&:nth-child(4) {
				margin-bottom: var(--routesListItemMarginBottom);
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.footer-main-content-wrap .row .third .storeSocialsWrap {
			flex-direction: column;

			.social-icon-wrap {
				margin-right: 0;
			}

			div:not(:last-child) {
				margin-bottom: var(--socialIconWrapMargin);
			}
		}

		.footer-main-content-wrap .row .third {
			align-items: center;
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--itemPadding: var(--sp1-5x);
		--itemPadTB: var(--sp4x);
		--routesListItemMarginBottom: var(--sp3x);
		--routesListMaxHeight: 100%;
		--secondPadL: 0;
		--footerAddressMaxWidth: var(--sp25x);
		--mailMarginTop: var(--sp3x);
		--phoneMarginTop: var(--sp3x);
		--socialIconWrapSizes: var(--sp8x);
		--socialIconSizes: var(--sp4x);
		--socialIconWrapMargin: var(--sp4x);

		--lowerFooterLogoMarginTop: var(--sp4x);
		--lowerFooterMarginTop: var(--sp4x);
		--copyRightPadding: 0 var(--sp1x);

		.footer-main-content-wrap .row .first .routes-list .routes-list-item {
			&:nth-child(4) {
				margin-bottom: var(--routesListItemMarginBottom);
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.footer-main-content-wrap .row .third .storeSocialsWrap {
			flex-direction: column;

			.social-icon-wrap {
				margin-right: 0;
			}

			div:not(:last-child) {
				margin-bottom: var(--socialIconWrapMargin);
			}
		}

		.footer-main-content-wrap .row .second .info-wrap {
			padding-top: var(--sp1x);
			padding-bottom: var(--sp1x);
		}

		.footer-main-content-wrap .row .third {
			align-items: center;
		}

		.copyright-wrap {
			flex-direction: column;
			justify-content: unset;

			.made-by-wrap {
				margin-top: var(--sp3x);
			}
		}
	}
`

export default Footer
