import { useMemo } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import { withDataContext, withLanguageContext } from 'context'

//* Components
import CustomLink from 'components/common/CustomLink'
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'
import Logo from 'components/common/Logo'

//* Style
import MobileMenuStyle from './style'

//* Config
import { config } from 'helpers'

const MobileMenu = ({ isBurgerMenuOpen, headerHeight, global, selectedLang, className }) => {
    //! Global Data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! Router
    const router = useRouter()

    //! Store menu routes
    const storeRoutes = useMemo(() => {
        const routes = Object.values(config.routes)

        return routes.filter(item => !['blog', 'solution'].includes(item.name)).map(
            (route, index) =>
                route.headerMenu && (
                    <li key={index} className='routes-list-item'>
                        <CustomLink url={route.path}>
                            <Text
                                className={`p ${router.pathname === route.path ? ' font-montserratarm-bold underline' : 'font-montserratarm-medium'
                                    }  uppercase`}
                                text={route.name}
                            />
                        </CustomLink>
                    </li>
                )
        )
    }, [router])

    //! Store socials
    const storeSocials = useMemo(() => {
        if (globalData) {
            const socials = Object.values(globalData.socials)
            const socialsKeys = Object.keys(globalData.socials)

            return socials.map((social, index) => (
                <div key={index}>
                    <CustomLink
                        target={'_blank'}
                        rel='noopener noreferrer'
                        url={social}
                        className={`social-icon-wrap ${socials.length - 1 === index ? 'last' : ''}`}
                        ariaLabel={socialsKeys[index]}
                    >
                        <Icon name={socialsKeys[index]} className={'social-icon'} />
                    </CustomLink>
                </div>
            ))
        }
    }, [globalData])

    return (
        <MobileMenuStyle headerHeight={headerHeight} className={`${isBurgerMenuOpen ? 'active' : ''} ${className}`}>
            <ul className='routes-list'>{storeRoutes}</ul>
            <div className={'socials-wrap'}>{storeSocials}</div>
            <Logo className={'burger-logo'} type={'burger'} />
        </MobileMenuStyle>
    )
}

export default withLanguageContext(withDataContext(MobileMenu, ['global']), ['selectedLang'])
