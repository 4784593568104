import App from 'next/app'
import dynamic from 'next/dynamic'
import React from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import Draggable from 'gsap/dist/Draggable'
import InertiaPlugin from 'gsap/dist/InertiaPlugin'
import Preloader from 'components/common/Preloader'

if (typeof window !== 'undefined') {
	gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin)
}

//* Providers
import { UIProvider, DataProvider, LanguageProvider } from 'context'

//* Components
import AppHeader from 'components/common/AppHeader'
import AppFooter from 'components/common/AppFooter'
// const Preloader = dynamic(() => import('components/common/Preloader'), { ssr: true })

//* Helpers
import { getData, getPageParams, config } from 'helpers'

//* Styles
import 'styles/font.css'
import 'styles/icons/style.css'
import 'styles/ant.normalize.css'
import 'swiper/css/bundle'

class MyApp extends App {
	static async getInitialProps({ Component, ctx, router, ...props }) {
		//! Checking If First Request or Not
		if (!process.browser) {
			//! Default States
			const newState = {
				pages: {},
				global: {},
			}

			//! Getting Page Data
			const data = await getData({ lang: ctx.locale, state: newState, router: ctx, getPageParams: true })

			return {
				pageProps: {
					...data,
					browser: false,
				},
			}
		} else {
			return {
				pageProps: {
					pageParams: getPageParams({ lang: ctx.locale, router: ctx }),
					browser: true,
				},
			}
		}
	}

	render() {
		const { Component, pageProps, router } = this.props

		return (
			<>
				<LanguageProvider>
					<UIProvider>
						<DataProvider newData={pageProps}>
							<Preloader />

							{router.pathname !== '/uikit' && <AppHeader />}

							<Component {...pageProps} config={config.routes} selectedLang={router.locale} />

							{router.pathname !== '/404' && router.pathname !== '/uikit' && <AppFooter />}
						</DataProvider>
					</UIProvider>
				</LanguageProvider>
			</>
		)
	}
}

export default MyApp
