import { forwardRef, useId } from 'react'
import { withLanguageContext } from 'context'
import Link from 'next/link'

const CustomLink = forwardRef(({ selectedLang, options, ...props }, ref) => {
    const id = useId()

    return (
        <Link
            id={id}
            scroll={props.scroll}
            shallow={props.shallow}
            href={props.url}
            ref={ref}
            rel={props.rel}
            className={`link-item ${props.className || ''}`}
            aria-label={props.ariaLabel}
            onMouseOver={props.onMouseOver}
            onMouseOut={props.onMouseOut}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick}
            target={props.target}
            {...options}
        >
            {props.content || props.children}
        </Link>
    )
})

CustomLink.displayName = 'CustomLink';

export default withLanguageContext(CustomLink, ['selectedLang'])
