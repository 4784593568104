import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`
    html, body {
        overflow-y: ${(props) => (props.isBurgerMenuOpen || props.isPreloaderPlaying ? 'hidden' : '')};

    ${(props) =>
		props.isBurgerMenuOpen || props.isPreloaderPlaying
			? ` 
                touch-action: none;
                -ms-touch-action: none;
            `
			: ``}
        }

    /* Typography */
    html {
        overflow-x: hidden;
        -webkit-text-size-adjust: 100%;
    }

    body {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        color: var(--primaryColor1);
        background: var(--primaryColor2); 
        font-family: Montserratarm;
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        position: relative;

        main {
            position: relative;
            min-height: 100vh;
            z-index: 1;
        }
        
        #__next {
            min-height: 100vh;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        &.hidden {
            overflow: hidden;
            max-height: 100vh;
        }
    }

    ul,
    fieldset {
        margin: 0;
        padding: 0;
        list-style: none;
        border: none;
    }

    * {
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0)!important; //! Touch highlight color 
        -webkit-font-smoothing: antialiased;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
        box-shadow: inset 1000px 1000px #fff;
    }

    input:focus::placeholder {
        color: transparent;
    }

    input {
        outline: none;
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: var(--primaryColor1) !important;
        box-shadow: inset 0 0 0 50px var(--primaryColor1) !important;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 2px solid var(--primaryColor1);
        -webkit-text-fill-color: var(--primaryColor1);
        color: var(--primaryColor1);
        background-color: var(--primaryColor1);
        box-shadow: 0 0 0 1000px var(--primaryColor1) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    //! Change the transparent to any color 
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px transparent inset !important;
    }

    @keyframes autofill {
        to {
            background: transparent;
        }
    }

    a {
        text-decoration: none;
        color: var(--black);
        -webkit-tap-highlight-color: transparent;
    }

    //! Font Sizes

    .h1,
    h1 {
        font-size: var(--h1);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h2,
    h2 {
        font-size: var(--h2);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h3,
    h3 {
        font-size: var(--h3);
        margin: 0;
        line-height: var(--lineHeightXS);
        font-weight: unset;
    }

    .h4,
    h4 {
        font-size: var(--h4);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .h5,
    h5 {
        font-size: var(--h5);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .h6,
    h6 {
        font-size: var(--h6);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .p, p {
        font-size: var(--p);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    .p1{
        font-size: var(--p1);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .p2{
        font-size: var(--p2);
        margin: 0;
        line-height: var(--lineHeightS);
        font-weight: unset;
    }

    .p3{
        font-size: var(--p3);
        margin: 0;
        line-height: var(--lineHeight);
        font-weight: unset;
    }

    //! Row
    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 calc(var(--colPadding) * -1);

        > * {
            width: 100%;
            padding: 0 var(--colPadding);
        }

        .col {
            flex: 1 1;
        }

        ${columnGenerator()}

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
            ${columnGenerator('dl')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
            ${columnGenerator('dm')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
            ${columnGenerator('ds')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
            ${columnGenerator('dxs')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
            ${columnGenerator('t')}
        }
        
        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
            ${columnGenerator('ts')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
            ${columnGenerator('m')}
        }
    }
`

export default Typography

function columnGenerator(suffix) {
	let style = ''
	for (let i = 1; i <= 12; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`
		style += `{ width: ${(100 / 12) * i}% !important } `
	}

	return style
}
