import styled from 'styled-components'

const LanguageSwitcherStyle = styled.div`
	position: relative;
	display: inline-flex;
	line-height: 1;

	.lang-item {
		width: 100%;
		background: transparent;
		transition: all var(--trTime);
		color: var(--primaryColor1);
		padding: 0 var(--sp1x);
	}
`

export default LanguageSwitcherStyle
