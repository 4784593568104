import styled from 'styled-components'

const ContainerStyle = styled.section.attrs({})`
	/* --contMaxWidth: var(--desktopSizeXL); */
	--contMarginV: var(--sp33x);
	--colPadding: var(--sp2x);

	width: 100%;
	margin: 0 auto;

	&:not(.full) {
		width: 100%;
		/* max-width: var(--contMaxWidth); */
		padding-left: var(--containerPadLR);
		padding-right: var(--containerPadLR);
	}

	&.first-section {
		margin-top: var(--contMarginV);
	}

	&.section {
		margin-bottom: var(--contMarginV);
	}

	&.full {
		padding-left: 0;
		padding-right: 0;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXLMin}) {
		/* --contMaxWidth: var(--desktopSizeL); */
		--contMarginV: var(--sp25x);
		--colPadding: var(--sp2x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeLMin}) {
		/* --contMaxWidth: var(--desktopSizeM); */
		--contMarginV: var(--sp20x);
		--colPadding: var(--sp2x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeMMin}) {
		/* --contMaxWidth: var(--desktopSizeS); */
		--contMarginV: var(--sp20x);
		--colPadding: var(--sp2x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeSMin}) {
		/* --contMaxWidth: var(--desktopSizeXS); */
		--contMarginV: var(--sp15x);
		--colPadding: var(--sp2x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		/* --contMaxWidth: var(--tabletSize); */
		--contMarginV: var(--sp12x);
		--colPadding: var(--sp2x);
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		/* --contMaxWidth: var(--tabletSizeS); */
		--contMarginV: var(--sp12x);
		--colPadding: var(--sp2x);
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		/* --contMaxWidth: 100%; */
		--contMarginV: var(--sp10x);
		--colPadding: var(--sp1x);
	}
`
export default ContainerStyle
