export default {
	colors: {
		ColorWhite: '#ffffff',
		ColorBlack: '#262626',
		ColorBlue: '#1AA4E4',
		ColorDarkGray: '#707070',
		ColorErrorRed: '#FF3434',
		ColorSuccessGreen: '#2AD960',
	},

	mediaQuery: {
		DesktopSizeXL: '2560px',
		DesktopSizeL: '1920px',
		DesktopSizeM: '1510px',
		DesktopSizeS: '1440px',
		DesktopSizeXS: '1280px',
		TabletSize: '1024px',
		TabletSizeS: '768px',
		MobileSize: '601px',
		MobileSizeS: '414px',

		DesktopSizeXLMin: '2559px',
		DesktopSizeLMin: '1919px',
		DesktopSizeMMin: '1509px',
		DesktopSizeSMin: '1439px',
		DesktopSizeXSMin: '1279px',
		TabletSizeMin: '1023px',
		TabletSizeSMin: '767px',
		MobileSizeSMin: '413px',
	},
}
