import { memo as Memo, useEffect, useCallback, useState, useId } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* GSAP
import { gsap, Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

const Animate = Memo(
    ({
        params,
        startAnimeArr,
        startPoint,
        endPoint,
        dependency,
        preloader,
        oneTime,
        startY,
        heightAuto,
        durationOpacity,
        durationHorizontal,
        durationVertical,
        durationLayer,
        stagger,
        delay,
        check,
        footer,
        textContent,
        ...props
    }) => {
        const id = useId()
        //! States
        const [isAnimated, setIsAnimated] = useState(false)

        const getDefaultParams = useCallback(
            (items, index) => ({
                ease: 'easeOut',
                scrollTrigger: {
                    trigger: items,
                    start: startPoint || '-=100% bottom',
                    toggleActions: 'play none none none',
                    delay: 0.1,
                    id: `${index}-${id}`,
                    footer,
                    // markers: true,
                },
            }),
            [startPoint, oneTime, footer]
        )

        const animateOpacityVertical = useCallback(
            (items, index) => {
                gsap.fromTo(
                    items,
                    {
                        y: startY || '100%',
                        opacity: 0,
                        pointerEvents: 'none',
                    },
                    {
                        ...getDefaultParams(items, index),
                        y: '0',
                        stagger: stagger || 0.1,
                        duration: durationOpacity || 1,
                        opacity: 1,
                        delay: delay || 0,
                    }
                ).then(() => {
                    gsap.set(items, { pointerEvents: 'all' })
                })
            },
            [startY, delay, durationOpacity, stagger]
        )

        const animateLineHorizontal = useCallback(
            (items, index) => {
                gsap.fromTo(
                    items,
                    {
                        width: 0,
                        opacity: 0,
                    },
                    {
                        ...getDefaultParams(items, index),
                        width: '100%',
                        stagger: stagger || 0.1,
                        duration: durationHorizontal || 1,
                        delay: delay || 0,
                        opacity: 1,
                    }
                )
            },
            [durationHorizontal, delay, stagger]
        )

        const animateLineVertical = useCallback(
            (items, index) => {
                gsap.fromTo(
                    items,
                    {
                        height: 0,
                    },
                    {
                        ...getDefaultParams(items, index),
                        height: heightAuto || '100%',
                        stagger: stagger || 0.1,
                        duration: durationVertical || 1,
                        delay: delay || 0,
                    }
                )
            },
            [durationVertical, stagger, heightAuto, delay]
        )

        const animateLayerTopToBottom = useCallback(
            (items, index) => {
                gsap.fromTo(
                    items,
                    {
                        top: 0,
                    },
                    {
                        ...getDefaultParams(items, index),
                        top: '100%',
                        duration: durationLayer || 1,
                    }
                )
            },
            [durationLayer]
        )

        const numberGrowth = useCallback((item) => {
            gsap.fromTo(
                item,
                {
                    textContent: 0,
                },
                {
                    ...getDefaultParams(item),
                    textContent: textContent,
                    ease: Power1.easeIn,
                    stagger: 1,
                    snap: { textContent: textContent.includes('.') ? 0.1 : 1 },
                    duration: 1,
                }
            )
        })

        const squareClipPathAnim = useCallback((item) => {
            gsap.to(item, {
                ...getDefaultParams(item),
                clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
                transition: check ? 0 : 0.55,
            })
        })

        const lineVerticalPosVersion = useCallback(
            (items, index) => {
                gsap.fromTo(
                    items,
                    {
                        bottom: '100%',
                    },
                    {
                        ...getDefaultParams(items, index),
                        stagger: stagger || 0.1,
                        duration: 1,
                        bottom: 0,
                    }
                )
            },
            [stagger]
        )

        useEffect(() => {
            if (params !== null && !preloader && !isAnimated) {
                Object.keys(params).forEach((type, i) => {
                    const items = params[type].reduce(
                        (arr, item) => (Array.isArray(item.current) ? [...arr, ...item.current] : [...arr, item.current]),
                        []
                    )
                    gsap.killTweensOf(items)

                    if (stagger) {
                        if (type === 'opacityVertical') {
                            animateOpacityVertical(
                                items.filter((element) => element !== undefined),
                                i
                            )
                        } else if (type === 'lineHorizontal') {
                            animateLineHorizontal(
                                items.filter((element) => element !== undefined),
                                i
                            )
                        } else if (type === 'layerTopToBottom') {
                            animateLayerTopToBottom(
                                items.filter((element) => element !== undefined),
                                i
                            )
                        } else if (type === 'lineVertical') {
                            animateLineVertical(
                                items.filter((element) => element !== undefined),
                                i
                            )
                        } else if (type === 'numberAnime') {
                            numberGrowth(
                                items.filter((element) => element !== undefined),
                                i
                            )
                        } else if (type === 'squareClipPathAnim') {
                            squareClipPathAnim(
                                items.filter((element) => element !== undefined),
                                i
                            )
                        } else if (type === 'lineVerticalPosVersion') {
                            lineVerticalPosVersion(
                                items.filter((element) => element !== undefined),
                                i
                            )
                        }
                    } else {
                        for (let item of items) {
                            if (item) {
                                if (type === 'opacityVertical') {
                                    animateOpacityVertical(item, i)
                                } else if (type === 'lineHorizontal') {
                                    animateLineHorizontal(item, i)
                                } else if (type === 'layerTopToBottom') {
                                    animateLayerTopToBottom(item, i)
                                } else if (type === 'lineVertical') {
                                    animateLineVertical(item, i)
                                } else if (type === 'numberAnime') {
                                    numberGrowth(item, i)
                                } else if (type === 'squareClipPathAnim') {
                                    squareClipPathAnim(item, i)
                                } else if (type === 'lineVerticalPosVersion') {
                                    lineVerticalPosVersion(item, i)
                                }
                            }
                        }
                    }

                    ScrollTrigger.refresh()
                })

                if (oneTime) {
                    setIsAnimated(true)
                }
            }

            return () => {
                Object.keys(params).forEach((type, i) => {
                    const anim = ScrollTrigger.getById(`${i}-${id}`)
                    if (anim) {
                        !oneTime && anim?.kill()
                    }
                })
            }
        }, [params, preloader, dependency, isAnimated, oneTime, textContent])

        // useEffect(() => {
        //     return () => {
        //         ScrollTrigger.getAll().forEach((t) => {
        //             (!t.animation.scrollTrigger.vars.footer || footer) && t.kill()
        //         })
        //     }
        // }, [dependency, footer])

        return props.children
    }
)

export default withUIContext(Animate, ['preloader'])
