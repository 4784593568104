import styled from 'styled-components'

const MobileMenuStyle = styled.div`
	/* 1280 - 1439 */
	--menuContentPadding: var(--sp10x) var(--sp3x) 0;
	--routeListItemMarginBottom: var(--sp5x);
	--socialIconWrapSizes: var(--sp10x);
	--socialIconWrapMargin: var(--sp5x);
	--socialIconSizes: var(--sp6x);

	position: fixed;
	top: 0;
	left: 100%;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	width: 100vw;
	height: 100%;
	padding: var(--menuContentPadding);
	background-color: var(--primaryColor2);
	z-index: 100;
	transition: var(--trTime);

	&.active {
		left: 0;
	}

	.burger-logo {
		opacity: 0.06;
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.routes-list {
		margin-top: ${(props) => `${props.headerHeight}px`};
		
        .routes-list-item {
			width: fit-content;
			margin-bottom: var(--routeListItemMarginBottom);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.socials-wrap {
		display: flex;
		margin-top: ${(props) => `${props.headerHeight}px`};

		.social-icon-wrap {
			display: flex;
			justify-content: center;
			align-items: center;
			width: var(--socialIconWrapSizes);
			height: var(--socialIconWrapSizes);
			border-radius: 50%;
			border: 1px solid var(--primaryColor1);
			margin-right: var(--socialIconWrapMargin);

			&.last {
				margin-right: 0;
			}

			.social-icon {
				font-size: var(--socialIconSizes);
			}
		}
	}

	/* 1024 - 1279 */
	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.DesktopSizeXSMin}) {
		--menuContentPadding: var(--sp9x) var(--sp3x) 0;
		--socialIconWrapSizes: var(--sp8x);
		--socialIconWrapMargin: var(--sp4x);
		--socialIconSizes: var(--sp5x);
	}

	/* 768 - 1023 */
	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
			theme.mediaQuery.TabletSizeMin}) {
		--menuContentPadding: var(--sp9x) var(--sp3x) 0;
		--socialIconWrapSizes: var(--sp8x);
		--socialIconWrapMargin: var(--sp4x);
		--socialIconSizes: var(--sp5x);
	}

	/* 414 - 767 */
	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--menuContentPadding: var(--sp8x) var(--sp3x) 0;
		--socialIconWrapSizes: var(--sp8x);
		--socialIconWrapMargin: var(--sp3x);
		--socialIconSizes: var(--sp5x);

		.socials-wrap {
			flex-direction: column;

			.social-icon-wrap {
				margin-right: unset;
				margin-bottom: var(--socialIconWrapMargin);

				&.last {
					margin-bottom: 0;
				}
			}
		}
	}

	/* 414 - 767 Landscape */

	@media only screen and (orientation: landscape) {
		--menuContentPadding: var(--sp4x) var(--sp3x) 0;
		--socialIconWrapSizes: var(--sp5x);
		--socialIconSizes: var(--sp3x);

		.routes-list {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			max-height: var(--sp25x);

			.routes-list-item {
				margin-right: var(--sp13x);
				margin-bottom: var(--sp2-5x);
			}
		}

		.socials-wrap {
			flex-direction: column;

			.link-item {
				margin-right: unset;
				margin-bottom: var(--sp2x);
			}
		}
	}
`

export default MobileMenuStyle
