export default {
	routes: {
		aboutUs: {
			path: '/about-us',
			slug: 'about-us',
			name: 'aboutUs',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
		},

		projects: {
			path: '/projects',
			slug: 'project',
			name: 'projects',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
			postType: {
				slug: 'projects',
				name: 'projects',
				queryParamKeys: ['serviceId', 'page'],
			},
		},

		services: {
			path: '/expertise',
			slug: 'service',
			name: 'expertise',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
			postType: {
				slug: 'services',
				name: 'services',
				queryParamKeys: [],
			},
		},

		solution: {
			path: '/solution',
			slug: 'solution',
			name: 'solution',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
		},

		materials: {
			path: '/materials',
			slug: 'materials',
			name: 'materials',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
		},

		blog: {
			path: '/blog',
			slug: 'blog',
			name: 'blog',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
			postType: {
				slug: 'posts',
				name: 'posts',
				queryParamKeys: ['tag_ids', 'page'],
			},
		},

		job: {
			path: '/career',
			slug: 'career',
			name: 'job',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
		},

		contactUs: {
			path: '/contact-us',
			slug: 'contact-us',
			name: 'contactUs',
			autoload: true,
			headerMenu: true,
			footerMenu: true,
		},

		home: {
			path: '/',
			slug: 'home',
			name: 'home',
			autoload: true,
			headerMenu: false,
			footerMenu: false,
		},

		singleServices: {
			path: '/expertise/:slug',
			slug: 'services',
			name: 'services',
			singleType: 'singleServices',
			autoload: true,
		},

		singleProject: {
			path: '/projects/:slug',
			slug: 'projects',
			name: 'projects',
			singleType: 'singleProjects',
			autoload: true,
		},

		singleBlog: {
			path: '/blog/:slug',
			slug: 'posts',
			name: 'post',
			singleType: 'singleBlog',
			autoload: true,
		},
	},
}
