import api from './_api'
import config from './_config'
import getContext from './_getContext'

import { getData, getPageParams } from './_getData'
import formValidation from './_formValidation'
import { objectToFormData } from './_objectToFormData'

import {
    queryStringToObject,
    objectToQueryString,
    routerToPathAndQuery,
    getParamsByKey,
    imagePathToWebP,
    isWebPSupport,
    stringToLetters,
    arrayGen,
    arrayGenChild,
    formatNumber,
    randomIntFromInterval
} from './_functions'

export {
    api,
    config,
    getContext,
    getData,
    getPageParams,
    queryStringToObject,
    objectToQueryString,
    routerToPathAndQuery,
    getParamsByKey,
    imagePathToWebP,
    isWebPSupport,
    stringToLetters,
    arrayGen,
    arrayGenChild,
    formValidation,
    objectToFormData,
    formatNumber,
    randomIntFromInterval
}
