import { forwardRef } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import { withUIContext } from 'context'

//* Components
import Image from 'components/common/Image'

//* Styles
import LogoStyle from './style'

const Logo = forwardRef((props, ref) => {
    //! Router
    const router = useRouter()

    const handleLogoClick = () => {
        props.closeBurgerMenu()
        if (router.pathname !== '/') {
            router.push('/')
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
    }

    return (
        <LogoStyle
            ref={ref}
            className={`${router.pathname !== '/' ? 'cursorPointer' : ''} ${props.className || ''} ${props.type || ''}`}
            onClick={handleLogoClick}
        >
            <Image src={'/logo.svg'} alt='logo' priority />
        </LogoStyle>
    )
})

export default withUIContext(Logo, ['closeBurgerMenu'])
