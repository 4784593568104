//* HOC's
import { withUIContext } from 'context'

//* Components
import LottieAnimation from 'components/common/LottieAnimation'

//* Style
import PreloaderStyle from './style'

//* Lottie
import preloaderLottie from '/lottieFiles/preloader.json'

const Preloader = ({ preloader, getLoaderEnd }) => {
	return preloader ? (
		<PreloaderStyle>
			<LottieAnimation animData={preloaderLottie} autoplay={true} loop={false} parentMethod={getLoaderEnd} />
		</PreloaderStyle>
	) : null
}

export default withUIContext(Preloader, ['preloader', 'getLoaderEnd'])
