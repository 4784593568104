import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import ContainerStyle from './style'

const Container = forwardRef(({ row, first, section, full, className, children, onMouseMove, onMouseLeave }, ref) => {
	const contRef = useRef()

	useImperativeHandle(ref, () => contRef.current, [])

	return (
		<ContainerStyle
			onMouseMove={onMouseMove}
			onMouseLeave={onMouseLeave}
			ref={contRef}
			className={`container ${className || ''}${section ? ' section' : ''}${full ? ' full' : ''}${first ? ' first-section' : ''}`}
		>
			{row ? <div className='row'>{children}</div> : children}
		</ContainerStyle>
	)
})

Container.displayName = 'Container'

export default Container
