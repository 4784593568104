import styled from 'styled-components'

const LogoStyle = styled.div`
	--headerLogoSize: var(--sp24x);

	width: var(--headerLogoSize);
	z-index: 1000;

	&.footer {
		--headerLogoSize: 100%;
		overflow: hidden;

		.link-item {
			display: block;
			margin-bottom: -2%;
		}

        .image-cont {
            img {
                object-fit: cover;
            }
        }
	}

	&.burger {
		--headerLogoSize: 100%;
	}

	a {
		width: 100%;
	}

	.imageCont {
		--imagePaddingTop: 27%;

		img {
			width: 100% !important;
			height: auto;
		}
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
		--headerLogoSize: var(--sp18x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
		--headerLogoSize: var(--sp14x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
		--headerLogoSize: var(--sp14x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
		--headerLogoSize: var(--sp12x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
		--headerLogoSize: var(--sp10x);
	}

	@media (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
		--headerLogoSize: var(--sp10x);
	}

	@media (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--headerLogoSize: var(--sp10x);
	}
`

export default LogoStyle
