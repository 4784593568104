// import _ from 'lodash'

// export function imagePathToWebP(src) {
// 	const pos = src.lastIndexOf('.'),
// 		image = src.includes('images.ctfassets') ? src + '?fm=webp' : src.substr(0, pos < 0 ? src.length : pos) + '.webp'
// 	return image
// }

// export const isMobile = () => {
// 	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
// }

// export const isWebPSupport = () => {
// 	const elem = typeof document === 'object' ? document.createElement('canvas') : {}
// 	if (!!(elem.getContext && elem.getContext('2d'))) {
// 		return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
// 	}
// 	return false
// }

// export const queryStringToObject = (url) => {
// 	return _.chain(url).replace('?', '').split('&').map(_.partial(_.split, _, '=', 2)).fromPairs().value()
// }

// export const objectToQueryString = (obj) => {
// 	if (obj) {
// 		const results = []
// 		_.forOwn(obj, (value, key) => {
// 			if (Array.isArray(value)) {
// 				_.forOwn(value, (value) => {
// 					results.push(`${key}=${value}`)
// 				})
// 			} else {
// 				results.push(`${key}=${value}`)
// 			}
// 		})
// 		return results.join('&')
// 	}
// 	return ''
// }

// export const stringToLetters = (text) => {
// 	let wordLength = text.split(' ')

// 	return (
// 		wordLength.map((i, k) => {
// 			return (
// 				<span className='word' key={k}>
// 					{i}
// 				</span>
// 			)
// 		})
// 	)
// }

// export const formatNumber = (x, leng) => {
// 	let str = x && (typeof x === 'string' ? x : x.toString())

// 	if (!x){ return 0}
// 	else {
// 		str = str.replace(',', '')
// 		str = str.replace(/\s/g, '')
// 		str = new Intl.NumberFormat('de-DE').format(str)
// 		return str
// 	}
// }

// //! generate random number between two numbers
// export const randomIntFromInterval = (min, max)=> {
// 	return Math.floor(Math.random() * (max - min + 1) + min)
// }
