import React, { forwardRef } from "react"

//* Provider
import { DataConsumer } from "../providers/DataProvider"

//* Helpers
import { getContext } from "helpers"

export default function withDataContext(ConnectedComponent, properties = []) {
    return forwardRef((props, ref) => {
        return (
            <DataConsumer>
                {context => {
                    let contextProps = properties.length ? getContext(context, properties) : {}
                    return <ConnectedComponent {...contextProps} {...props} ref={ref} />
                }}
            </DataConsumer>
        )
    })
}
