import React, { Component, createContext } from 'react'

//* HOCs
import { withRouter } from 'next/router'

//* Helpers
import { ThemeProvider } from 'styled-components'
import themes from 'styles/theme'
import HelperClass from 'styles/helperClass'
import Variables from 'styles/variables'
import Typography from 'styles/typography'

//* Global Variables
const errorsInitialState = {
	formError: {
		error: false,
		names: [],
		msg: {},
	},
	systemError: {
		error: false,
		msg: '',
	},
}

const UIContext = createContext(null)

class UIProvider extends Component {
	//! States
	state = {
		winWidth: 1920,
		winHeight: 1080,
		screenSizes: {},
		...errorsInitialState,
		preloaderHide: false,
		preloader: true,
		popupIsOpen: false,
		popupComponent: null,
		isBurgerMenuOpen: false,
	}

	//! Methods
	methods = {
		disableDomScroll: this.disableDomScroll.bind(this),
		enableDomScroll: this.enableDomScroll.bind(this),
		toggleBurgerMenu: this.toggleBurgerMenu.bind(this),
		closeBurgerMenu: this.closeBurgerMenu.bind(this),
		getLoaderEnd: this.getLoaderEnd.bind(this),
	}

	//! When preloader ends
	getLoaderEnd() {
		this.setState({ preloader: !this.state.preloader })
	}

	//! Toggle burger menu
	toggleBurgerMenu() {
		this.setState({
			isBurgerMenuOpen: !this.state.isBurgerMenuOpen,
		})
	}

	//! Close burger menu
	closeBurgerMenu() {
		this.setState({
			isBurgerMenuOpen: false,
		})
	}

	//! Screen Resize
	screenResize = () => {
		this.setState({
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		})
	}

	//! Get Media Screen Sizes from Css Variables
	getScreenSizes() {
		const root = getComputedStyle(document.documentElement)

		const screenSizes = {
			desktopSizeXL: parseInt(root.getPropertyValue('--desktopSizeXL')),
			desktopSizeL: parseInt(root.getPropertyValue('--desktopSizeL')),
			desktopSizeM: parseInt(root.getPropertyValue('--desktopSizeM')),
			desktopSizeS: parseInt(root.getPropertyValue('--desktopSizeS')),
			desktopSizeXS: parseInt(root.getPropertyValue('--desktopSizeXS')),
			tabletSize: parseInt(root.getPropertyValue('--tabletSize')),
			tabletSizeS: parseInt(root.getPropertyValue('--tabletSizeS')),
			mobileSize: parseInt(root.getPropertyValue('--mobileSize')),
		}

		this.setState({ screenSizes })
	}

	//! Disable DOM Scroll
	disableDomScroll() {
		// document.querySelector('html').classList.add('hide-scroll')

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

		// if any scroll is attempted, set this to the previous value
		window.onscroll = function (e) {
			e.preventDefault()
			window.scrollTo(scrollLeft, scrollTop)
		}
	}

	//! Enable DOM Scroll
	enableDomScroll() {
		// document.querySelector('html').classList.remove('hide-scroll');

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

		window.onscroll = function () {}
		window.scrollTo(scrollLeft, scrollTop)
	}

	//! Component Did Mount
	componentDidMount() {
		this.setState({
			...this.state,
		})

		window.addEventListener('resize', this.screenResize)
		this.getScreenSizes()
		this.screenResize()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.router.asPath !== this.props.router.asPath) {
			this.setState({
				...this.state,
			})
		}
	}

	//! Component Will Unmount
	componentWillUnmount() {
		window.addEventListener('resize', this.screenResize)
		document.removeEventListener('keydown', this.escKeydown, false)
	}

	render() {
		return (
			<UIContext.Provider value={{ ...this.state, ...this.methods }}>
				<ThemeProvider theme={themes}>
					<Variables />
					<Typography isPreloaderPlaying={this.state.preloader} isBurgerMenuOpen={this.state.isBurgerMenuOpen} />
					<HelperClass />

					{this.props.children}
				</ThemeProvider>
			</UIContext.Provider>
		)
	}
}

export default withRouter(UIProvider)
export const UIConsumer = UIContext.Consumer
