import styled from 'styled-components'

const HeaderStyle = styled.header`
	--headerContentWrapPadding: var(--sp6x) var(--sp10x);
	--routesListItemMarginRight: var(--sp5x);
	--routesListItemLastChildMarginRight: var(--sp15x);
	--burgerMenuIconMarginLeft: var(--sp6x);
	--activeLineTop: 276%;

	z-index: 1000;

	position: absolute;
	top: 0;
	width: 100%;

	&:not(.opacityHeader) {
		& + main {
			padding-top: ${(props) => `${props.headerHeight || 0}px`};
		}
	}

	.header-content-wrap {
		display: flex;
		align-items: center;
		padding: var(--headerContentWrapPadding);

		.routes-list {
			display: flex;
			margin-left: auto;

			.routes-list-item {
				position: relative;
				margin-right: var(--routesListItemMarginRight);

				.link-item {
					display: inline-block;
				}

				&.active {
					pointer-events: none !important;

					.link-item {
						pointer-events: none !important;
					}

					&:after {
						content: '';
						position: absolute;
						top: var(--activeLineTop);
						left: 0;
						width: 100%;
						height: 3px;
						background-color: var(--primaryColor1);
					}
				}

				@media (hover: hover) {
					&:hover {
						&:before {
							width: 100%;
						}
					}
				}

				&:before {
					content: '';
					position: absolute;
					top: 100%;
					width: 0%;
					height: 1px;
					background-color: var(--primaryColor1);
					transition: var(--trTime);
				}

				&:last-child {
					margin-right: var(--routesListItemLastChildMarginRight);
				}
			}
		}

		.burger-menu-icon {
			width: var(--sp4x);
			height: var(--sp4x);
			cursor: pointer;
			margin-left: var(--burgerMenuIconMarginLeft);
			z-index: 1000;
		}
	}

	.line-wrap {
		position: relative;
		padding: var(--headerContentWrapPadding);
		padding-top: 0;
		padding-bottom: 0;
		z-index: 1000;
	}

	.language-switcher {
		z-index: 1000;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeL}) and (max-width: ${({ theme }) =>
        theme.mediaQuery.DesktopSizeXLMin}) {
		--headerContentWrapPadding: var(--sp5x) var(--sp8x);
		--routesListItemMarginRight: var(--sp5x);
		--routesListItemLastChildMarginRight: var(--sp12x);
		--activeLineTop: 274%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeM}) and (max-width: ${({ theme }) =>
        theme.mediaQuery.DesktopSizeLMin}) {
		--headerContentWrapPadding: var(--sp4x);
		--routesListItemMarginRight: var(--sp4x);
		--routesListItemLastChildMarginRight: var(--sp11x);
		--activeLineTop: 229%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeS}) and (max-width: ${({ theme }) =>
        theme.mediaQuery.DesktopSizeMMin}) {
		--headerContentWrapPadding: var(--sp3x);
		--routesListItemMarginRight: var(--sp4x);
		--routesListItemLastChildMarginRight: var(--sp10x);
		--activeLineTop: 195%;
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXS}) and (max-width: ${({ theme }) =>
        theme.mediaQuery.DesktopSizeSMin}) {
		--headerContentWrapPadding: var(--sp3x);

		.language-switcher {
			margin-left: auto;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSize}) and (max-width: ${({ theme }) =>
        theme.mediaQuery.DesktopSizeXSMin}) {
		--headerContentWrapPadding: var(--sp3x);
		--burgerMenuIconMarginLeft: var(--sp5x);

		.language-switcher {
			margin-left: auto;
		}
	}

	@media only screen and (min-width: ${({ theme }) => theme.mediaQuery.TabletSizeS}) and (max-width: ${({ theme }) =>
        theme.mediaQuery.TabletSizeMin}) {
		--headerContentWrapPadding: var(--sp3x);
		--burgerMenuIconMarginLeft: var(--sp5x);

		.language-switcher {
			margin-left: auto;
		}
	}

	@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
		--headerContentWrapPadding: var(--sp2x) var(--sp1x);
		--burgerMenuIconMarginLeft: var(--sp4x);

		.language-switcher {
			margin-left: auto;
		}
	}
`

export default HeaderStyle
