import { forwardRef, useState, useEffect, useMemo, useImperativeHandle, useRef } from 'react'
import Link from 'next/link'

//* HOCs
import { withLanguageContext } from 'context'
import { useRouter } from 'next/router'

//* Styles
import LanguageSwitcherStyle from './style'

const LanguageSwitcher = forwardRef((props, ref) => {
    //! Router
    const router = useRouter()

    //! Refs
    const languageSwitcherRef = useRef()

    useImperativeHandle(ref, () => languageSwitcherRef.current, [])

    //! States
    const [activeLanguage, setActiveLanguage] = useState(props.languages.en.params.name)

    useEffect(() => {
        router.locale === 'en' ? setActiveLanguage(props.languages.hy.params.name) : setActiveLanguage(props.languages.en.params.name)
    }, [router])

    const getReversedLanguageName = useMemo(() => {
        return props.translate(router.locale === 'en' ? props.languages.hy.params.name : props.languages.en.params.name)
    }, [router])

    return (
        <LanguageSwitcherStyle className={`${props.className ? props.className : ''} language-switcher`} ref={languageSwitcherRef}>
            <Link href={router.asPath} locale={activeLanguage} className={`font-montserrat-medium p uppercase lang-item`}>
                {getReversedLanguageName}
            </Link>
        </LanguageSwitcherStyle>
    )
})

export default withLanguageContext(LanguageSwitcher, ['selectLanguage', 'languages', 'translate'])
