import styled from 'styled-components'


const ImageStyle = styled.div.attrs('')`
    --imagePaddingTop: 50.5%;

    &.imageCont {
        position: relative;
        width: 100%;
        padding-top: var(--imagePaddingTop);

        > span {
            position: absolute;
            top: 0;
            left: 0;
            object-position: center;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
`
export default ImageStyle
